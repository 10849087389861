<template>
  <table class="simpleTable">
    <thead>
      <tr>
        <th rowspan="2" style="width:120px;">
          <b>Travel Date</b>
        </th>
        <th rowspan="2" style="width:100px;">
          <b>Flight</b>
        </th>
        <th rowspan="2" style="width:100px;">
          <b>Class</b>
        </th>
        <th rowspan="2" style="width:100px;">
          <b>Dep</b>
        </th>
        <th rowspan="2" style="width:80px;">
          <b>From</b>
        </th>
        <th rowspan="2" style="width:80px;">
          <b>To</b>
        </th>
        <th rowspan="2" style="width:100px;">
          <b>Pax</b>
        </th>
        <th rowspan="2" style="width:100px;">
          <b>Fare</b>
        </th>
        <th colspan="2">
          <b>Amount</b>
        </th>
      </tr>
      <tr>
        <th style="width:120px;">
          <b>MMK</b>
        </th>
        <th style="width:110px;">
          <b>USD</b>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="waies in voucher.way">
        <template
          v-if="waies.wayStatus == null && (waies.status == 'ACTIVE' || waies.status == 'D' || waies.status == 'S' || waies.status == 'DNS')"
        >
          <template v-if="waies.commission <= 0">
            <tr :key="waies.wayId">
              <td style="padding: 0px">{{waies.travelDate}}</td>
              <td v-if="waies.className == 'DATACHANGE'" colspan="3">DATE CHANGE</td>
              <td v-if="waies.className != 'DATACHANGE'">{{waies.flight.flightName}}</td>
              <td v-if="waies.className != 'DATACHANGE'">{{waies.className}}</td>
              <td v-if="waies.className != 'DATACHANGE'">{{waies.dept}}</td>
              <td>{{waies.fromSector.shortName}}</td>
              <td>{{waies.toSector.shortName}}</td>
              <td>{{waies.pass | perssengerCount(waies.roundTripId)}}</td>
              <td
                v-if="waies.usdAmount > 0"
                class="text-right"
              >{{waies.fare | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
              <td class="text-right" v-else>{{waies.fare | defaultPaymentReturnBlankFormat}}</td>
              <td class="text-right">{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
              <td
                class="text-right"
              >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
            </tr>
            <tr v-if="waies.commission != 0" :key="waies.commission">
              <td colspan="6">COMMISSION</td>
              <td>{{waies.pass | perssengerCount}}</td>
              <td
                class="text-right"
                v-if="waies.usdTotalCommission < 0"
              >{{waies.commission | defaultPaymentWithDollarSignFormat}}</td>
              <td class="text-right" v-else>{{waies.commission | defaultPaymentFormat}}</td>

              <td
                class="text-right"
                v-if="waies.mmkTotalCommission < 0 && waies.usdTotalCommission == 0"
              >{{waies.mmkTotalCommission | defaultPaymentFormat}}</td>
              <td v-else></td>

              <td
                class="text-right"
                v-if="waies.usdTotalCommission < 0 && waies.mmkTotalCommission == 0"
              >{{waies.usdTotalCommission | defaultPaymentWithDollarSignFormat}}</td>
              <td v-else></td>
            </tr>
            <tr v-if="waies.status == 'S'" :key="waies.wayId">
              <td colspan="6">SECTOR CHANGE</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <!-- <td>{{waies.pass | perssengerCount}}</td>
              <td
                v-if="waies.usdAmount > 0"
                class="text-right"
              >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
              <td class="text-right" v-else>{{waies.fare | defaultPaymentFormat}}</td>
              <td
                class="text-right"
              >{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
              <td
                class="text-right"
              >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>-->
            </tr>
            <!-- update 20/3/2020 -->
            <!-- <tr v-if="waies.status == 'D'" :key="waies.wayId">
              <td colspan="6">DATE CHANGE</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td> -->
              <!-- end -->
              <!-- <td>{{waies.pass | perssengerCount}}</td>
              <td
              v-if="waies.usdAmount > 0"
              class="text-right"
              >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
              <td v-else class="text-right">{{waies.fare | defaultPaymentFormat}}</td>
              <td
                class="text-right"
              >{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
              <td
                class="text-right"
              >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>-->
            <!-- </tr>
            <tr v-if="waies.status == 'DNS'" :key="waies.wayId">
              <td colspan="6">DATE AND SECTOR CHANGE</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr> -->
          </template>
          <template v-else>
            <tr :key="waies.wayId">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </template>
        </template>
        <template v-else-if="waies.status == 'N'">
          <tr :key="waies.wayId">
            <td colspan="6">NAME CHANGE</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              v-if="waies.usdAmount > 0"
              class="text-right"
            >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.fare | defaultPaymentFormat}}</td>
            <td class="text-right">{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
            <td
              class="text-right"
            >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
        </template>
        <!-- <template v-else-if="waies.status == 'S'">
          <tr :key="waies.wayId">
            <td colspan="6">SECTOR CHANGE</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              v-if="waies.usdAmount > 0"
              class="text-right"
            >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.fare | defaultPaymentFormat}}</td>
            <td
              class="text-right"
            >{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
            <td
              class="text-right"
            >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
        </template>
        <template v-else-if="waies.status == 'D'">
          <tr :key="waies.wayId">
            <td colspan="6">DATE CHANGE</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              v-if="waies.usdAmount > 0"
              class="text-right"
            >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
            <td v-else class="text-right">{{waies.fare | defaultPaymentFormat}}</td>
            <td
              class="text-right"
            >{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
            <td
              class="text-right"
            >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
        </template>-->
        <template v-else-if="waies.status == 'C'">
          <tr :key="waies.wayId">
            <td colspan="6">CHILD</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              v-if="waies.usdAmount > 0"
              class="text-right"
            >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.fare | defaultPaymentFormat}}</td>
            <td class="text-right">{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
            <td
              class="text-right"
            >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
          <tr v-if="waies.commission != 0" :key="waies.commission">
            <td colspan="6">COMMISSION</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              class="text-right"
              v-if="waies.usdTotalCommission < 0"
            >{{waies.commission | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.commission | defaultPaymentFormat}}</td>

            <td
              class="text-right"
              v-if="waies.mmkTotalCommission < 0 && waies.usdTotalCommission == 0"
            >{{waies.mmkTotalCommission | defaultPaymentFormat}}</td>
            <td v-else></td>

            <td
              class="text-right"
              v-if="waies.usdTotalCommission < 0 && waies.mmkTotalCommission == 0"
            >{{waies.usdTotalCommission | defaultPaymentWithDollarSignFormat}}</td>
            <td v-else></td>
          </tr>
        </template>
        <template v-else-if="waies.status == 'I'">
          <tr :key="waies.wayId">
            <td colspan="6">INFANT</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              v-if="waies.usdAmount > 0"
              class="text-right"
            >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.fare | defaultPaymentFormat}}</td>
            <td class="text-right">{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
            <td
              class="text-right"
            >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
          <tr v-if="waies.commission != 0" :key="waies.commission">
            <td colspan="6">COMMISSION</td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              class="text-right"
              v-if="waies.usdTotalCommission < 0"
            >{{waies.commission | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.commission | defaultPaymentFormat}}</td>

            <td
              class="text-right"
              v-if="waies.mmkTotalCommission < 0 && waies.usdTotalCommission == 0"
            >{{waies.mmkTotalCommission | defaultPaymentFormat}}</td>
            <td v-else></td>

            <td
              class="text-right"
              v-if="waies.usdTotalCommission < 0 && waies.mmkTotalCommission == 0"
            >{{waies.usdTotalCommission | defaultPaymentWithDollarSignFormat}}</td>
            <td v-else></td>
          </tr>
        </template>
        <template v-else-if="waies.wayStatus == 'WEIGHT' || waies.wayStatus == 'WHEEL'">
          <tr :key="waies.wayId">
            <td
              colspan="6"
            >{{waies.description}} for ({{waies.fromSector.shortName}} - {{waies.toSector.shortName}})
            {{(waies.round)?"(Round Trip)":""}}
            </td>
            <td>{{waies.pass | perssengerCount}}</td>
            <td
              v-if="waies.usdAmount > 0"
              class="text-right"
            >{{waies.fare | defaultPaymentWithDollarSignFormat}}</td>
            <td class="text-right" v-else>{{waies.fare | defaultPaymentFormat}}</td>
            <td class="text-right">{{waies.mmkAmount | defaultPaymentReturnBlankFormat}}</td>
            <td
              class="text-right"
            >{{waies.usdAmount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
        </template>
      </template>
      <tr>
        <th class="text-left noBorder" rowspan="4" colspan="7">
          <v-row no-gutters>
            <v-col cols="2">
              <b>Remark :</b>
            </v-col>
            <v-col cols="10">
              <span class="underlineVoucher">
                {{voucher.pnr}}
                <br />
                <span class="underlineVoucher">{{voucher.remark}}</span>
              </span>
            </v-col>
            <v-col cols="2">
              <b>Issued By :</b>
            </v-col>
            <v-col cols="10">
              <span class="underlineVoucher">{{voucher.issuedBy}}</span>
            </v-col>
          </v-row>
        </th>
        <th v-if="voucher.mmkDiscount > 0 || voucher.usdDiscount > 0">
          <b>Discount</b>
        </th>
        <td
          v-if="voucher.mmkDiscount > 0 || voucher.usdDiscount > 0"
          class="text-right"
        >{{voucher.mmkDiscount | defaultPaymentReturnBlankFormat}}</td>
        <td
          v-if="voucher.mmkDiscount > 0 || voucher.usdDiscount > 0"
          class="text-right"
        >{{voucher.usdDiscount | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
      </tr>
      <tr>
        <th>
          <b>Total Amount</b>
        </th>
        <td class="text-right">{{voucher.mmkTotal | defaultPaymentReturnBlankFormat}}</td>
        <td class="text-right">{{voucher.usdTotal | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
      </tr>
      <tr>
        <th>
          <b>Received</b>
        </th>
        <td class="text-right">{{voucher.mmkReceive | defaultPaymentReturnBlankFormat}}</td>
        <td
          class="text-right"
        >{{voucher.usdReceive | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
      </tr>
      <tr>
        <th>
          <b>Balance</b>
        </th>
        <td
          class="text-right"
        >{{voucher.mmkBalance | defaultBalanceReturnBlankFormat(voucher.mmkReceive)}}</td>
        <td
          class="text-right"
        >{{voucher.usdBalance | defaultBalanceWithDollarSignFormatReturnBlank(voucher.usdReceive)}}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    company: Object,
    voucher: {
      toCustomer: {
        customerName: "",
        phone: ""
      }
    },
    file: String
  }
};
</script>
<style scoped>
.underlineVoucher {
  padding-bottom: 2px;
  border-bottom: dotted 2px black;
}
.simpleTable td {
  border: 1px solid black;
  border-radius: 3px;
  white-space: pre-wrap;
  padding: 0 5px 0 5px;
}
.simpleTable th {
  font-weight: normal;
  border: 1px solid black;
  border-radius: 5px;
  white-space: pre-wrap;
}
.noBorder {
  border: 0px !important;
}
.innerTable {
  border-collapse: collapse;
  border-style: hidden;
}
.innerTable td,
.innerTable th {
  border: 1px solid black;
}
.innerTable tr:first-child th {
  border-top: 0px;
}
.innerTable tr:last-child td {
  border-bottom: 0;
}
.innerTable tr td:first-child,
.innerTable tr th:first-child {
  border-left: 0;
}
.innerTable tr td:last-child,
.innerTable tr th:last-child {
  border-right: 0;
}

td::before {
  content: " ";
}
</style>